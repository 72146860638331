<template>
  <div class="message-panel-wrapper">
    <div
      v-if="data.message"
      class="message-panel"
      :class="{ 'message-panel--active': isMessagePanelOpen }"
    >
      <span class="icon close" @click="close()">L</span>
      <p
        v-html="data.message"
        :style="`color: ${data.color || '#000'}; font-size: ${data.fontSize || 20}px`"
      ></p>
    </div>
  </div>
</template>

<script>
import Message from '../../../message.json';
export default {
  data() {
    return {
      data: Message,
      isMessagePanelOpen: false
    };
  },
  methods: {
    close() {
      localStorage.setItem('confirmedMessage', this.data.message);
      this.isMessagePanelOpen = false;
    }
  },
  mounted() {
    // const confirmed = localStorage.getItem('confirmedMessage');
    // if (confirmed !== this.data.message) {
      setTimeout(() => {
        this.isMessagePanelOpen = true;
      }, 1000);
    // }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.message-panel-wrapper {
  position: fixed;
}
.message-panel {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  -webkit-box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.35);
  display: flex;
  max-height: 90vh;
  max-width: 950px;
  padding: 30px 20px;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 1;
  @include transition(all $transition $cubic);
  @include transform(translateY(-50%) translateX(100%));
  &.message-panel--active {
    @include transform(translateY(-50%));
  }
  &::before {
    background-image: url('../../assets/img/home/bg-message.jpg');
    background-size: cover;
    content: '';
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .close {
    font-size: 1.2rem;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    @include noselect();
    @include hover();
  }
  p {
    font-weight: 400;
    /* text-shadow: rgb(3, 3, 3) 5px 0 10px; */
    max-height: 100%;
    padding: 20px;
    position: relative;
    text-align: left;
    line-height: 26px;
    overflow: auto;
    z-index: 1;
    @include scrollbar(5px, #dadada);
  }
}
@media screen and (max-width: $sm) {
  .message-panel {
    padding: 20px;
  }
}
@media screen and (max-width: $xs) {
  .message-panel {
    max-width: 95%;
  }
}
</style>